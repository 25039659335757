<template>
	<div class="information-content center-noflex">
		<Stitle title="挂牌交易信息" subtitle="Listing information"></Stitle>
		<div class="information-List">
			<div class="List-Item" v-for="(item, index) in jiaoyiList" :key="index" @click="GOdetali(item)">
				<div>
					<div class="Item-title">{{item.title}}</div>
					<div class="Item-subtitle">委托方：{{item.prower}}</div>
				</div>
				<div class="Item-spikel"></div>
				<div class="center-noflex font-first" style="margin: 0 20px 0 0">
					<div style="margin-bottom: 4px">距离截止还有</div>
					<div class="Item-button">{{item.times}}天</div>
				</div>
			</div>
		</div>
		<div class="information-more" @click="GOList(0)">更多></div>
		<Stitle title="挂牌结果信息" subtitle="Listing result information"></Stitle>
		<div class="information-List">
			<div class="List-Item" v-for="(item, index) in jieguoList" :key="index" @click="GOdetali(item)">
				<div>
					<div class="Item-title">{{item.title}}</div>
					<div class="align-center">
						<div class="tag">摘牌候选人</div>
						<div class="Item-subtitle">{{item.prower}}</div>
					</div>
				</div>
				<div class="Item-spikel"></div>
				<div class="align-center font-first" style="margin: 0 20px 0 0">
					公示期已结束
				</div>
			</div>
		</div>
		<div class="information-more" @click="GOList(1)">更多></div>
		<Stitle title="成交公告" subtitle="Transaction announcement"></Stitle>
		<div class="information-List">
			<div class="List-Item" v-for="(item, index) in gonggaoList" :key="index" @click="GOdetali(item)">
				<div style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-right: 30px;
          ">
					<div class="Item-title">
						<!-- “一种新型具有荧光特性的交联丙烯酸酯乳液及其
            制备方法发明专利权挂牌交易” -->
						{{item.title}}
					</div>
					<div class="flex">
						<div class="tag">摘牌人</div>
						<div class="Item-subtitle">{{item.prower}}</div>
					</div>
				</div>
				<div class="Item-spikel"></div>
				<div class="align-center" style="margin-left: 50px">
					<img src="@/assets/information/information_Icon1.png" alt="" />
				</div>
			</div>
		</div>
		<div class="information-more" @click="GOList(2)">更多></div>
	</div>
</template>

<script>
	import Stitle from "components/title.vue";

	export default {
		components: {
			Stitle,
		},
		async created() {
			const {
				data
			} = await this.$api({
				c: "acticle",
				a: "lists",
			});
			const value = data.arr
			//console.log(value)
			this.jiaoyiList = value.gpjy
			this.jieguoList = value.gpjg
			this.gonggaoList = value.cjgg

		},
		data() {
			return {
				jiaoyiList: [],
				jieguoList: [],
				gonggaoList: [],
			};
		},
		methods: {
			GOList(key) {
				this.$router.push("/index/information/list?key=" + key);
				//console.log(key)
			},
			GOdetali(e) {
				this.$router.push("/index/informationdetali?id=" + e.id);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.information-List {
		width: 1200px;
		display: flex;
		flex-wrap: wrap;
		// display: grid;
		// grid-template-columns: 590px 590px;
		// column-gap: 20px;
		
		justify-content: space-between;
		margin: 40px 0 30px;

		.List-Item {
			width: 590px;
			padding: 18px 30px;
		margin-bottom: 20px;	
			padding-right: 30px;
			background: #ffffff;
			display: flex;

			.Item-title {
				color: rgb(0, 118, 254);
				margin-bottom: 14px;
			}

			.Item-subtitle {
				font-size: 12px;
				color: #676767;
			}

			.Item-spikel {
				height: 100%;
				width: 1px;
				background: #dfdfdf;
				margin: 0 auto;
			}

			.tag {
				background: url("../../assets/information/information_tag.png");
				background-size: 100% 100%;
				padding-right: 10px;
				padding-top: 1px;
				padding-left: 4px;
				color: #ffffff;
				font-size: 12px;
				text-align: center;
				margin-right: 10px;
			}

			.Item-button {
				width: 100%;
				text-align: center;
				padding: 2px 0;
				background: rgb(0, 118, 254);
				color: #ffffff;
				font-size: 12px;
			}
		}
	}

	.information-content {
		background: rgb(248, 248, 248);
		white-space: normal;
		padding-top: 54px;
	}

	.information-more {
		width: 122px;
		height: 33px;
		line-height: 33px;
		border-radius: 100px;
		border: 1px solid rgb(0, 118, 254);
		color: rgb(0, 118, 254);
		text-align: center;
		margin-bottom: 50px;
	}
</style>
